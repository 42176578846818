<template>
	<GridImage
		:src="src"
		prevent-drag
	/>
</template>

<script>
import GridImage from '@user/components/grid-components/image/GridImage.vue';
import { getOptimizedSrc } from '@user/utils/getSrcsets';

// hardcoded reasonable value to prevent massive images in builder
const MAX_WIDTH = 1920;

export default {
	name: 'GridImageProviderBuilder',
	components: { GridImage },
	props: {
		data: {
			type: Object,
			required: true,
		},
	},
	computed: {
		src: ({ data }) => getOptimizedSrc(data.settings.image, {
			isLossless: true,
			width: MAX_WIDTH,
		}),
	},
};
</script>
